<template>
  <v-card-text>
    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn icon :disabled="workstudyCalendarDay === 'Sunday'" @click="prevDay">
        <v-icon>fal fa-chevron-left</v-icon>
      </v-btn>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ workstudyCalendarDay }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="({ text, value }, index) in workstudyCalendarDays" :key="'days-' + index" @click="workstudyCalendarDate = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon :disabled="workstudyCalendarDay === 'Saturday'" @click="nextDay">
        <v-icon>fal fa-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-calendar
      v-if="calendarStart !== ''"
      v-model="workstudyCalendarDate"
      ref="workstudyCalendar"
      type="category"
      category-show-all
      :categories="workstudyStudents"
      :events="workstudyEvents"
      :first-interval="7"
      :interval-minutes="60"
      :interval-count="14"
      :interval-height="48"
      :short-weekdays="true"
      :start="calendarStart"
      :event-color="(event) => { return event.color }"
      style="max-height:700px"
    ></v-calendar>
  </v-card-text>
</template>
<style>
.v-calendar.theme--dark.v-calendar-events .v-event-timed.primary {
  color: #1E1E1E !important
}
.v-calendar.theme--dark.v-calendar-events .v-event-timed.grey {
  color: black !important
}
.v-calendar .theme--dark.v-card.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-calendar .theme--dark.v-card.grey.lighten-4 .v-toolbar__title {
  color: #1E1E1E !important;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-daily_head-day-label, .v-calendar-daily.v-calendar-category .v-calendar-daily_head-weekday {
  display: none;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-category__column-header {
  padding: .3em 0;
}
.v-calendar .v-event-timed {
  white-space: pre-wrap;
}
.v-menu__content.theme--dark .v-card.theme--dark.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-menu__content.theme--dark .v-card.theme--dark .v-toolbar.theme--dark.primary {
  color: #1E1E1E !important;
}
.v-calendar-daily_head-day-label {
  display: none;
}
.v-calendar-category__column-header {
  padding: 8px 0;
}
</style>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const placement = computed(() => root.$route.params.placement)
    const term = ref('')
    const calendarStart = ref('')
    const workstudyCalendar = ref(null)
    const workstudyCalendarDays = ref([])
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    onMounted(async () => {
      // Look up the current/next term
      const { data } = await root.$feathers.service('system/term').find({ query: { term: { $regex: '05$|60$' }, end: { $gte: new Date() }, $sort: { start: 1 }, $limit: 1 } })
      if (data.length > 0) {
        term.value = data[0].term
        const start = new Date(data[0].start)
        // We have the first day of the term, but it might be in the middle of the week; so go to the next week then subtract the day of the week so we end up at the beginning of the next week
        start.setDate(start.getDate() + 7 - start.getDay())
        calendarStart.value = start.toISOString().substring(0, 10)

        for (let i = 0; i < days.length; i++) {
          workstudyCalendarDays.value.push({ text: days[i], value: start.toISOString().substring(0, 10) })
          start.setDate(start.getDate() + 1)
          if (workstudyCalendarDate.value === '') workstudyCalendarDate.value = start.toISOString().substring(0, 10)
        }
      }
    })
    const workstudyCalendarDate = ref('')
    const workstudyCalendarDay = computed(() => {
      let dt = new Date(workstudyCalendarDate.value + ' 8:00')
      return days[dt.getDay()]
    })
    const workstudyStudents = ref([])
    const workstudyEvents = ref([])

    function prevDay () {
      workstudyCalendar.value.prev()
    }
    function nextDay () {
      workstudyCalendar.value.next()
    }

    const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey']
    watch([term, placement], () => { loadData() })
    async function loadData () {
      if (term.value === '' || placement.value === '' || placement.value == null) return
      workstudyStudents.value = []
      workstudyEvents.value = []

      const aggregate = [
        { $match: { type: 'workstudy', code: placement.value } },
        { $project: { title: 1, group: { $first: '$groups' } } },
        { $project: { title: 1, people: '$group.people' } },
        { $lookup: {
          from: 'Directory',
          localField: 'people',
          foreignField: '_id',
          as: 'student'
        } },
        { $unwind: '$student' },
        { $lookup: {
          from: 'Calendar-Classes',
          localField: 'student.pidm',
          foreignField: 'students.pidm',
          as: 'classes',
          let: { pidm: '$student.pidm' },
          pipeline: [
            { $unwind: '$students' },
            {
              $match: {
                term: term.value,
                $expr: {
                  $eq: ['$students.pidm', '$$pidm']
                }
              }
            },
            {
              $project: {
                title: 1,
                meets: {
                  $reduce: {
                    input: '$meetingBase',
                    initialValue: '',
                    in: {
                      $concat: [
                        '$$value',
                        { $cond: [ { $eq: ['$$value', ''] }, '', '<br/>' ] },
                        { $cond: [ { $eq: ['$$this.days', ''] }, '', { $concat: [ '$$this.days', ' ' ] } ] },
                        { $cond: [ { $eq: [ '$$this.startTime', '' ] }, '', { $concat: [ '$$this.startTime', ' - ' ] } ] },
                        '$$this.endTime',
                        { $cond: [ { $eq: [ '$$this.room', null ] }, '', { $concat: [ ' in ', '$$this.room', ' (', '$$this.building', ')' ] } ] }
                      ]
                    }
                  }
                }
              }
            },
            { $match: { meets: { $ne: '' } } }
          ]
        } },
        {
          $project: {
            classes: 1,
            category: { $concat: [ '$student.name.first', ' ', '$student.name.last' ] },
            email: '$student.email'
          }
        }
      ]
      const { data } = await root.$feathers.service('directory/list').find({ query: { aggregate } })
      let colorIndex = 0
      const arr = []
      for (const { category, classes } of data) {
        const color = colors[colorIndex % colors.length] + (colorIndex > colors.length ? ' darken-' + Math.floor(colorIndex / colors.length) * 2 : '')
        workstudyStudents.value.push(category)
        parseMeetings({ meets: 'MWF 11:00am - 11:35am', name: 'Chapel', color, category }, arr)
        for (const { meets, title } of classes) {
          // console.log({ meets, title })
          parseMeetings({ meets, name: title, color, category }, arr)
        }
        colorIndex++
      }
      workstudyEvents.value = arr
    }

    function parseMeetings ({ meets, name, color, category }, arr) {
      const weekDays = { M: 1, T: 2, W: 3, R: 4, F: 5, S: 6 }
      let [ year, month, day ] = calendarStart.value.split('-')
      day = parseInt(day)
      if (meets === '' || meets === '**No Meeting Times Specified**') {
        return
      }

      // console.log(meets)

      const temp = meets.split('<br/>')
      for (const time of temp) {
        const [days, startTime, endTime] = time.replace(' - ', ' ').split(' ')
        if (startTime && endTime) {
          let [startHour, startMin] = startTime.split(':')
          let [endHour, endMin] = endTime.split(':')
          startHour = parseInt(startHour)
          if (startMin.substring(2) === 'pm' && startHour !== 12) startHour += 12
          startMin = startMin.substring(0, 2)
          const start = (startHour < 10 ? '0' : '') + startHour + ':' + startMin
          endHour = parseInt(endHour)
          if (endMin.substring(2) === 'pm' && endHour !== 12) endHour += 12
          endMin = endMin.substring(0, 2)
          const end = (endHour < 10 ? '0' : '') + endHour + ':' + endMin

          for (let k = 0; k < days.length; k++) {
            // console.log({
            //   start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
            //   end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end
            // })
            arr.push({
              name,
              start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
              end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end,
              color,
              category
            })
          }
        }
      }
    }

    return {
      placement,
      term,
      calendarStart,
      workstudyCalendar,
      workstudyCalendarDays,
      workstudyCalendarDate,
      workstudyCalendarDay,
      workstudyStudents,
      workstudyEvents,
      prevDay,
      nextDay
    }
  }
}
</script>
